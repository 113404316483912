import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Mutex } from "async-mutex";

import { config } from "@/config";
import { type RootState } from "@/store";
import { authApi } from "@/store/services/auth.service";

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
        const accessToken = (getState() as RootState).auth.accessToken;
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
    },
});

// Using a mutex to prevent multiple unauthorized errors
// https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
const mutex = new Mutex();
const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
    // Wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    // Handle token expiration or unauthorized response (401)
    if (result.error && result.error.status === 401) {
        // Checking whether the mutex is locked
        if (!mutex.isLocked()) {
            const release = await mutex.acquire();

            const userId = (api.getState() as RootState).auth.userId;
            const userDisplayName = (api.getState() as RootState).auth.userDisplayName;

            if (!userId || !userDisplayName) {
                release();
                return result;
            }

            // Try to refresh the token
            const refreshPromise = api.dispatch(authApi.endpoints.refresh.initiate());

            const refetchResult = await refreshPromise;
            if (refetchResult.error) {
                release();
                return result;
            }

            // Retry the original request
            result = await baseQuery(args, api, extraOptions);

            // Release must be called once the mutex should be released again.
            release();
        } else {
            // Wait until the mutex is available without locking it
            await mutex.waitForUnlock();

            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};

export const rootApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
});
