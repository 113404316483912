import z from "zod";
const customerOverviewDtoSchema = z.object({
    customerName: z.string(),
    customerNumber: z.string(),
    lelyCenterName: z.string().nullable(),
    numberOfRobotsMovex: z.number().int(),
    numberOfRobotsLicenseServer: z.number().int(),
    movexLicenseServerDifference: z.number().int(),
});
export { customerOverviewDtoSchema as robotComparisonCustomerOverviewDtoSchema, };
