import styles from "@/components/ErrorFallback/ErrorFallback.module.css";

/**
 * Error fallback component to be rendered by an error boundary in case it encounters any error
 *
 * @returns rendered error fallback
 */
export const ErrorFallback = () => {
    return (
        <div className={styles.error_fallback}>
            <h1>Something went wrong :(</h1>
            <a href="/">Go to homepage</a>
        </div>
    );
};
