import { accessTokenPayloadSchema } from "@lely-it-observability/api-schema";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

import {
    getLocalStorageItem,
    removeLocalStorageItem,
    setLocalStorageItem,
    STORAGE_KEY,
} from "@/utils/local-storage.utils";

type LogoutReason = "sessionExpired" | "userLoggedOut";
interface AuthState {
    accessToken: string | null;
    userId: string | null;
    userDisplayName: string | null;
    logoutReason: LogoutReason | null;
}

const extractDataFromAccessToken = (accessToken: string | null) => {
    if (accessToken === null) {
        return {
            userId: null,
            displayName: null,
        };
    }

    const decodedAccessToken = jwtDecode<unknown>(accessToken);

    const parsedAccessToken = accessTokenPayloadSchema.passthrough().safeParse(decodedAccessToken);
    if (!parsedAccessToken.success) {
        console.error(parsedAccessToken.error);
        throw new Error("Invalid access token format");
    }

    return {
        userId: parsedAccessToken.data.userId,
        displayName: parsedAccessToken.data.displayName,
    };
};

const loadedAccessToken = getLocalStorageItem(STORAGE_KEY.ACCESS_TOKEN);
const loadedAccessTokenData = extractDataFromAccessToken(loadedAccessToken);

const initialState: AuthState = {
    accessToken: loadedAccessToken,
    userId: loadedAccessTokenData.userId,
    userDisplayName: loadedAccessTokenData.displayName,
    logoutReason: null,
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        login: (state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
            const { accessToken, refreshToken } = action.payload;

            const accessTokenData = extractDataFromAccessToken(accessToken);

            state.accessToken = accessToken;
            state.userId = accessTokenData.userId;
            state.userDisplayName = accessTokenData.displayName;

            state.logoutReason = null;

            setLocalStorageItem(STORAGE_KEY.ACCESS_TOKEN, accessToken);
            setLocalStorageItem(STORAGE_KEY.REFRESH_TOKEN, refreshToken);
        },
        logout: (state, action: PayloadAction<{ logoutReason: LogoutReason }>) => {
            state.logoutReason = action.payload.logoutReason;

            state.accessToken = null;
            state.userId = null;
            state.userDisplayName = null;

            removeLocalStorageItem(STORAGE_KEY.ACCESS_TOKEN);
            removeLocalStorageItem(STORAGE_KEY.REFRESH_TOKEN);
        },
    },
});

export const { login, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
