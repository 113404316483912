import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { AppDispatch, RootState } from "@/store/store";

/**
 * Export typed `useSelector` hook (https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks)
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
/**
 * Export typed `useDispatch` hook (https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks)
 */
export const useAppDispatch: () => AppDispatch = useDispatch;
