import {
    LoginRequestDtoSchema,
    LoginResponseDtoSchema,
    RefreshRequestDtoSchema,
    RefreshResponseDtoSchema,
} from "@lely-it-observability/api-schema";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { config } from "@/config";
import { login, logout } from "@/store/slices/auth.slice";
import { getLocalStorageItem, STORAGE_KEY } from "@/utils/local-storage.utils";

const baseUrl = `${config.API_BASE_URL}auth`;
const baseQuery = fetchBaseQuery({ baseUrl });

export const authApi = createApi({
    reducerPath: "authApi",
    baseQuery,
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponseDtoSchema, LoginRequestDtoSchema>({
            query: (data) => ({
                url: "/login",
                method: "POST",
                body: data,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(login(data));
                } catch {
                    // Errors are displayed in the form on the login page
                }
            },
        }),
        refresh: builder.mutation<RefreshResponseDtoSchema, void>({
            query: () => {
                const refreshToken = getLocalStorageItem(STORAGE_KEY.REFRESH_TOKEN);

                return {
                    url: "/refresh",
                    method: "POST",
                    body: {
                        refreshToken,
                    } as RefreshRequestDtoSchema,
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(login(data));
                } catch {
                    // Logout if refresh failed
                    dispatch(logout({ logoutReason: "sessionExpired" }));
                }
            },
        }),
    }),
});

export const { useLoginMutation } = authApi;
