import { Button } from "@datamole/wds-component-button";
import { Menu } from "@datamole/wds-component-menu";
import { faAngleDown, faUser, faRightFromBracket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "@/components/Layout/Navbar/Navbar.module.css";
import { useAppDispatch, useAppSelector } from "@/store";
import { logout } from "@/store/slices/auth.slice";

const UserMenu = () => {
    const userDisplayName = useAppSelector((state) => state.auth.userDisplayName);
    const dispatch = useAppDispatch();

    return (
        <Menu>
            <Menu.Trigger asChild>
                <Button className={styles.action_button}>
                    <Button.Icon>
                        <FontAwesomeIcon icon={faUser} />
                    </Button.Icon>
                    <Button.Content>{userDisplayName}</Button.Content>
                    <Button.Icon>
                        <FontAwesomeIcon icon={faAngleDown} />
                    </Button.Icon>
                </Button>
            </Menu.Trigger>
            <Menu.Content align={"end"} side={"bottom"}>
                <Menu.Content.List>
                    <Menu.Content.Item onClick={() => dispatch(logout({ logoutReason: "userLoggedOut" }))}>
                        <Menu.Content.Item.Icon>
                            <FontAwesomeIcon icon={faRightFromBracket} />
                        </Menu.Content.Item.Icon>
                        <Menu.Content.Item.Content>Logout</Menu.Content.Item.Content>
                    </Menu.Content.Item>
                </Menu.Content.List>
            </Menu.Content>
        </Menu>
    );
};

export { UserMenu };
