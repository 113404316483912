import { z } from "zod";
export const databaseSizeCheckFarmOverviewDtoSchema = z.object({
    customerName: z.string(),
    customerNumber: z.string(),
    customerCountry: z.string().nullable(),
    farmLicenseKey: z.string(),
    clusterName: z.string(),
    lelyCenterName: z.string().nullable(),
    databaseSize: z.number(),
});
