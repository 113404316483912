import { Alert } from "@datamole/wds-component-alert";

const LoginPageSessionExpiredWarning = () => {
    return (
        <Alert severity="warning">
            <Alert.Icon />
            <Alert.Content>
                <Alert.Title>Session Expired</Alert.Title>
                Your session has expired. Please log in again.
            </Alert.Content>
        </Alert>
    );
};

export { LoginPageSessionExpiredWarning };
