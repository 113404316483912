import z from "zod";
import { horizonServiceCategory } from "./service-category.enum";
const faultyServiceSchema = z.object({
    name: z.string(),
    category: horizonServiceCategory,
});
export const horizonServicesCustomerOverviewDtoSchema = z.object({
    customerName: z.string(),
    customerNumber: z.string(),
    farmLicenseKey: z.string(),
    customerCountry: z.string().nullable(),
    faultyServices: z.array(faultyServiceSchema),
    clusterName: z.string(),
    lelyCenterName: z.string().nullable(),
});
