import {
    faHome,
    faRectangleCode,
    faTag,
    faMicrochip,
    faRobot,
    faChartTreeMap,
    faDatabase,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

import { RoutesPaths, routes } from "@/routes";

export type SidebarLinkItem = {
    name: string;
    to: RoutesPaths;
    icon?: ReactNode;
};

export type SidebarCollapsibleToggleItem = {
    name: string;
    icon?: ReactNode;
    subitems: SidebarLinkItem[];
};

// Defines contents for Sidebar menu
export const sidebarItems = {
    dashboard: {
        to: routes.dashboard.path,
        name: "Dashboard",
        icon: <FontAwesomeIcon icon={faHome} />,
    },

    software: {
        name: "Software",
        icon: <FontAwesomeIcon icon={faRectangleCode} />,
        subitems: [
            {
                to: routes.horizonVersion.path,
                name: "Horizon Version",
                icon: <FontAwesomeIcon icon={faTag} />,
            },
            {
                to: routes.horizonServices.path,
                name: "Horizon Services",
                icon: <FontAwesomeIcon icon={faChartTreeMap} />,
            },
            {
                to: routes.horizonDatabaseHealth.path,
                name: "Database Health",
                icon: <FontAwesomeIcon icon={faDatabase} />,
            },
            {
                to: routes.horizonDatabaseSizeCheck.path,
                name: "Database Size Check",
                icon: <FontAwesomeIcon icon={faDatabase} />,
            },
        ],
    },

    hardware: {
        name: "Hardware",
        icon: <FontAwesomeIcon icon={faMicrochip} />,
        subitems: [
            {
                to: routes.numberOfRobots.path,
                name: "Number of Robots",
                icon: <FontAwesomeIcon icon={faRobot} />,
            },
        ],
    },
} satisfies Record<string, SidebarLinkItem | SidebarCollapsibleToggleItem>;
